
import { Component, Inject, Prop, Provide, Vue } from 'nuxt-property-decorator'
import type { Location } from 'vue-router'

@Component
export default class SidebarButton extends Vue {
  @Prop(Object) readonly to!: Location
  @Prop(String) readonly title?: string
  @Prop(String) readonly icon?: string
  @Prop({ type: Number, default: 0 }) readonly badgeCount!: number
  @Prop({ type: Boolean, default: false }) readonly lastLevel?: boolean

  @Provide('isChild') provideParent = true
  @Inject({ default: false }) public isChild!: boolean

  buttonClasses (isActive: boolean, isExactActive: boolean): string[] {
    const c = (isExactActive || ((this.isChild || this.lastLevel) && isActive))
      ? ['font-semibold', 'text-opacity-80']
      : ['font-light', 'text-opacity-60']

    return c.concat(this.isChild ? ['pl-md', 'text-xs'] : ['text-sm'])
  }

  linkClasses (isExactActive, isChild, lastLevel, isActive): string[] {
    const base = lastLevel ? ['pl-lg', 'pr-md'] : ['px-md']
    if (isExactActive || ((isChild || lastLevel) && isActive)) {
      return base.concat('bg-white', 'bg-opacity-20')
    } else {
      return base.concat('border-opacity-0')
    }
  }
}
