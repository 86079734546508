
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { type Notification, notifications } from '~/lib/notifications'

@Component
export default class Notifications extends Vue {
  @Prop(Boolean) left?: boolean
  @Prop(Boolean) toolbar?: boolean

  get notifications () {
    return notifications.list
  }

  get position () {
    const pos = this.left ? 'items-start' : 'items-end'
    const toolbar = this.toolbar ? 'top-xl' : 'top-0'
    return [pos, toolbar]
  }

  getClassForNotification (notif: Notification) {
    switch (notif.type) {
      case 'information':
        return [
          'bg-information',
          'border-information-contrast',
          'text-information-contrast'
        ]
      case 'success':
        return [
          'bg-success',
          'border-success-contrast',
          'text-success-contrast'
        ]
      case 'critical':
        return [
          'bg-critical',
          'border-critical-contrast',
          'text-critical-contrast'
        ]
    }
  }
}
