
import { Component, Prop, Vue } from 'nuxt-property-decorator'

export type LayoutDirection = 'vertical' | 'horizontal'
export type LayoutJustify = 'around' | 'between' | 'start' | 'end' | 'evenly' | 'center'
export type LayoutItems = 'center' | 'start' | 'end' | 'stretch'
export type LayoutSpacing = null | 'none' | 'xsmall' | 'small' | 'medium' | 'intermediate' | 'large' | 'full'
export type LayoutWidth = null | 'none' | 'xsmall' | 'small' | 'medium' | 'intermediate' | 'large' | 'full' | 'almost-full'
export type MaxWidth = LayoutWidth | 'xlarge' | 'screenSmall' | 'screenMedium' | 'screenLarge' | 'screenXlarge'

@Component
export default class NuLayout extends Vue {
  @Prop({ type: String, default: 'vertical' }) readonly direction!: LayoutDirection
  @Prop(String) readonly mdDirection?: LayoutDirection
  @Prop(String) readonly lgDirection?: LayoutDirection

  @Prop({ type: Boolean, default: false }) readonly reversed!: boolean
  @Prop(Boolean) readonly mdReversed?: boolean
  @Prop(Boolean) readonly lgReversed?: boolean

  @Prop({ type: String, default: 'start' }) readonly justify!: LayoutJustify
  @Prop(String) readonly mdJustify?: LayoutJustify
  @Prop(String) readonly lgJustify?: LayoutJustify

  @Prop({ type: String, default: 'start' }) readonly items!: LayoutItems
  @Prop(String) readonly mdItems?: LayoutItems
  @Prop(String) readonly lgItems?: LayoutItems

  @Prop({ type: String, default: null }) readonly spacing!: LayoutSpacing
  @Prop(String) readonly mdSpacing?: LayoutSpacing
  @Prop(String) readonly lgSpacing?: LayoutSpacing

  @Prop({ type: String, default: null }) readonly spacingX!: LayoutSpacing
  @Prop(String) readonly mdSpacingX?: LayoutSpacing
  @Prop(String) readonly lgSpacingX?: LayoutSpacing

  @Prop({ type: String, default: null }) readonly spacingY!: LayoutSpacing
  @Prop(String) readonly mdSpacingY?: LayoutSpacing
  @Prop(String) readonly lgSpacingY?: LayoutSpacing

  @Prop({ type: String, default: null }) readonly margin!: LayoutSpacing
  @Prop({ type: String, default: null }) readonly marginX!: LayoutSpacing
  @Prop({ type: String, default: null }) readonly marginY!: LayoutSpacing

  @Prop({ type: String, default: null }) readonly padding!: LayoutSpacing
  @Prop({ type: String, default: null }) readonly paddingX!: LayoutSpacing
  @Prop({ type: String, default: null }) readonly paddingY!: LayoutSpacing

  @Prop({ type: String, default: 'full' }) readonly width!: LayoutWidth
  @Prop({ type: String, default: null }) readonly height!: LayoutWidth
  @Prop({ type: String, default: null }) readonly maxWidth!: MaxWidth

  get spacingClass (): string {
    switch (this.spacing) {
      case 'none':
        return 'space-0'
      case 'xsmall':
        return 'space-xs'
      case 'small':
        return 'space-sm'
      case 'medium':
        return 'space-md'
      case 'intermediate':
        return 'space-it'
      case 'large':
        return 'space-lg'
      case 'full':
        return 'space-full'
      case null:
        return ''
    }
  }

  get mdSpacingClass (): string {
    switch (this.mdSpacing) {
      case 'none':
        return 'md:space-0'
      case 'xsmall':
        return 'md:space-xs'
      case 'small':
        return 'md:space-sm'
      case 'medium':
        return 'md:space-md'
      case 'intermediate':
        return 'md:space-it'
      case 'large':
        return 'md:space-lg'
      case 'full':
        return 'md:space-full'
      default:
        return ''
    }
  }

  get lgSpacingClass (): string {
    switch (this.lgSpacing) {
      case 'none':
        return 'lg:space-0'
      case 'xsmall':
        return 'lg:space-xs'
      case 'small':
        return 'lg:space-sm'
      case 'medium':
        return 'lg:space-md'
      case 'intermediate':
        return 'lg:space-it'
      case 'large':
        return 'lg:space-lg'
      case 'full':
        return 'lg:space-full'
      default:
        return ''
    }
  }

  get spacingXClass (): string {
    switch (this.spacingX) {
      case 'none':
        return 'space-x-0'
      case 'xsmall':
        return 'space-x-xs'
      case 'small':
        return 'space-x-sm'
      case 'medium':
        return 'space-x-md'
      case 'intermediate':
        return 'space-x-it'
      case 'large':
        return 'space-x-lg'
      case 'full':
        return 'space-x-full'
      case null:
        return ''
    }
  }

  get mdSpacingXClass (): string {
    switch (this.mdSpacingX) {
      case 'none':
        return 'md:space-x-0'
      case 'xsmall':
        return 'md:space-x-xs'
      case 'small':
        return 'md:space-x-sm'
      case 'medium':
        return 'md:space-x-md'
      case 'intermediate':
        return 'md:space-x-it'
      case 'large':
        return 'md:space-x-lg'
      case 'full':
        return 'md:space-x-full'
      default:
        return ''
    }
  }

  get lgSpacingXClass (): string {
    switch (this.lgSpacingX) {
      case 'none':
        return 'lg:space-x-0'
      case 'xsmall':
        return 'lg:space-x-xs'
      case 'small':
        return 'lg:space-x-sm'
      case 'medium':
        return 'lg:space-x-md'
      case 'intermediate':
        return 'lg:space-x-it'
      case 'large':
        return 'lg:space-x-lg'
      case 'full':
        return 'lg:space-x-full'
      default:
        return ''
    }
  }

  get spacingYClass (): string {
    switch (this.spacingY) {
      case 'none':
        return 'space-0'
      case 'xsmall':
        return 'space-y-xs'
      case 'small':
        return 'space-y-sm'
      case 'medium':
        return 'space-y-md'
      case 'intermediate':
        return 'space-y-it'
      case 'large':
        return 'space-y-lg'
      case 'full':
        return 'space-y-full'
      default:
        return ''
    }
  }

  get mdSpacingYClass (): string {
    switch (this.mdSpacingY) {
      case 'none':
        return 'md:space-y-0'
      case 'xsmall':
        return 'md:space-y-xs'
      case 'small':
        return 'md:space-y-sm'
      case 'medium':
        return 'md:space-y-md'
      case 'intermediate':
        return 'md:space-y-it'
      case 'large':
        return 'md:space-y-lg'
      case 'full':
        return 'md:space-y-full'
      default:
        return ''
    }
  }

  get lgSpacingYClass (): string {
    switch (this.lgSpacingY) {
      case 'none':
        return 'lg:space-y-0'
      case 'xsmall':
        return 'lg:space-y-xs'
      case 'small':
        return 'lg:space-y-sm'
      case 'medium':
        return 'lg:space-y-md'
      case 'intermediate':
        return 'lg:space-y-it'
      case 'large':
        return 'lg:space-y-lg'
      case 'full':
        return 'lg:space-y-full'
      default:
        return ''
    }
  }

  get flexClass (): string {
    switch (this.direction) {
      case 'vertical':
        return !this.reversed ? 'flex-col' : 'flex-col-reverse'
      case 'horizontal':
        return !this.reversed ? 'flex-row' : 'flex-row-reverse'
    }
  }

  get mdFlexClass (): string {
    switch (this.mdDirection || this.direction) {
      case 'vertical':
        return !this.reversed ? 'md:flex-col' : 'md:flex-col-reverse'
      case 'horizontal':
        return !this.reversed ? 'md:flex-row' : 'md:flex-row-reverse'
    }
  }

  get lgFlexClass (): string {
    switch (this.lgDirection || this.mdDirection || this.direction) {
      case 'vertical':
        return !this.reversed ? 'lg:flex-col' : 'lg:flex-col-reverse'
      case 'horizontal':
        return !this.reversed ? 'lg:flex-row' : 'lg:flex-row-reverse'
    }
  }

  get justifyClass (): string {
    switch (this.justify) {
      case 'around':
        return 'justify-around'
      case 'between':
        return 'justify-between'
      case 'start':
        return 'justify-start'
      case 'end':
        return 'justify-end'
      case 'evenly':
        return 'justify-evenly'
      case 'center':
        return 'justify-center'
    }
  }

  get mdJustifyClass (): string {
    switch (this.mdJustify || this.justify) {
      case 'around':
        return 'md:justify-around'
      case 'between':
        return 'md:justify-between'
      case 'start':
        return 'md:justify-start'
      case 'end':
        return 'md:justify-end'
      case 'evenly':
        return 'md:justify-evenly'
      case 'center':
        return 'md:justify-center'
    }
  }

  get lgJustifyClass (): string {
    switch (this.lgJustify || this.mdJustify || this.justify) {
      case 'around':
        return 'lg:justify-around'
      case 'between':
        return 'lg:justify-between'
      case 'start':
        return 'lg:justify-start'
      case 'end':
        return 'lg:justify-end'
      case 'evenly':
        return 'lg:justify-evenly'
      case 'center':
        return 'lg:justify-center'
    }
  }

  get itemsClass (): string {
    switch (this.items) {
      case 'center':
        return 'items-center'
      case 'stretch':
        return 'items-stretch'
      case 'start':
        return 'items-start'
      case 'end':
        return 'items-end'
    }
  }

  get mdItemsClass (): string {
    switch (this.mdItems || this.items) {
      case 'center':
        return 'md:items-center'
      case 'stretch':
        return 'md:items-stretch'
      case 'start':
        return 'md:items-start'
      case 'end':
        return 'md:items-end'
    }
  }

  get lgItemsClass (): string {
    switch (this.lgItems || this.mdItems || this.items) {
      case 'center':
        return 'lg:items-center'
      case 'stretch':
        return 'lg:items-stretch'
      case 'start':
        return 'lg:items-start'
      case 'end':
        return 'lg:items-end'
    }
  }

  get marginClass (): string {
    switch (this.margin) {
      case 'none':
        return 'm-0'
      case 'xsmall':
        return 'm-xs'
      case 'small':
        return 'm-sm'
      case 'medium':
        return 'm-md'
      case 'intermediate':
        return 'm-it'
      case 'large':
        return 'm-lg'
      case 'full':
        return 'm-full'
      case null:
        return ''
    }
  }

  get marginXClass (): string {
    switch (this.marginX) {
      case 'none':
        return 'mx-0'
      case 'xsmall':
        return 'mx-xs'
      case 'small':
        return 'mx-sm'
      case 'medium':
        return 'mx-md'
      case 'intermediate':
        return 'mx-it'
      case 'large':
        return 'mx-lg'
      case 'full':
        return 'mx-full'
      case null:
        return ''
    }
  }

  get marginYClass (): string {
    switch (this.marginY) {
      case 'none':
        return 'my-0'
      case 'xsmall':
        return 'my-xs'
      case 'small':
        return 'my-sm'
      case 'medium':
        return 'my-md'
      case 'intermediate':
        return 'my-it'
      case 'large':
        return 'my-lg'
      case 'full':
        return 'my-full'
      case null:
        return ''
    }
  }

  get paddingClass (): string {
    switch (this.padding) {
      case 'none':
        return 'p-0'
      case 'xsmall':
        return 'p-xs'
      case 'small':
        return 'p-sm'
      case 'medium':
        return 'p-md'
      case 'intermediate':
        return 'p-it'
      case 'large':
        return 'p-lg'
      case 'full':
        return 'p-full'
      case null:
        return ''
    }
  }

  get paddingXClass (): string {
    switch (this.paddingX) {
      case 'none':
        return 'px-0'
      case 'xsmall':
        return 'px-xs'
      case 'small':
        return 'px-sm'
      case 'medium':
        return 'px-md'
      case 'intermediate':
        return 'px-it'
      case 'large':
        return 'px-lg'
      case 'full':
        return 'px-full'
      case null:
        return ''
    }
  }

  get paddingYClass (): string {
    switch (this.paddingY) {
      case 'none':
        return 'py-0'
      case 'xsmall':
        return 'py-xs'
      case 'small':
        return 'py-sm'
      case 'medium':
        return 'py-md'
      case 'intermediate':
        return 'py-it'
      case 'large':
        return 'py-lg'
      case 'full':
        return 'py-full'
      case null:
        return ''
    }
  }

  get widthClass (): string {
    switch (this.width) {
      case 'none':
        return 'w-0'
      case 'xsmall':
        return 'w-xs'
      case 'small':
        return 'w-sm'
      case 'medium':
        return 'w-md'
      case 'intermediate':
        return 'w-it'
      case 'large':
        return 'w-lg'
      case 'full':
        return 'w-full'
      case 'almost-full':
        return 'w-almost-full'
      case null:
        return ''
    }
  }

  get heightClass (): string {
    switch (this.height) {
      case 'none':
        return 'h-0'
      case 'xsmall':
        return 'h-xs'
      case 'small':
        return 'h-sm'
      case 'medium':
        return 'h-md'
      case 'intermediate':
        return 'h-it'
      case 'large':
        return 'h-lg'
      case 'full':
        return 'h-full'
      case 'almost-full':
        return 'h-almost-full'
      case null:
        return ''
    }
  }

  get maxWidthClass (): string {
    switch (this.maxWidth) {
      case 'none':
        return 'max-w-none'
      case 'xsmall':
        return 'max-w-xs'
      case 'small':
        return 'max-w-sm'
      case 'medium':
        return 'max-w-md'
      case 'intermediate':
        return 'max-w-it'
      case 'large':
        return 'max-w-lg'
      case 'xlarge':
        return 'max-w-xl'
      case 'full':
        return 'max-w-full'
      case 'almost-full':
        return 'max-w-almost-full'
      case 'screenSmall':
        return 'max-w-screen-sm'
      case 'screenMedium':
        return 'max-w-screen-md'
      case 'screenLarge':
        return 'max-w-screen-lg'
      case 'screenXlarge':
        return 'max-w-screen-xl'
      case null:
        return ''
    }
  }

  get classes () {
    return [
      this.flexClass,
      this.mdFlexClass,
      this.lgFlexClass,

      this.justifyClass,
      this.mdJustifyClass,
      this.lgJustifyClass,

      this.itemsClass,
      this.mdItemsClass,
      this.lgItemsClass,

      this.spacingClass,
      this.mdSpacingClass,
      this.lgSpacingClass,

      this.spacingXClass,
      this.mdSpacingXClass,
      this.lgSpacingXClass,

      this.spacingYClass,
      this.mdSpacingYClass,
      this.lgSpacingYClass,

      this.marginClass,
      this.marginXClass,
      this.marginYClass,

      this.paddingClass,
      this.paddingXClass,
      this.paddingYClass,

      this.heightClass,
      this.widthClass,
      this.maxWidthClass
    ]
  }
}
