
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { hideDefaultCrispLauncher } from 'nuag-core-utils/crisp'

@Component
export default class Sidebar extends Vue {
  $refs!: Vue['$refs'] & { helpPanel: { toggle: (event: Event) => void }}

  @Prop(Boolean) readonly forceUnfold!: boolean

  mounted () {
    hideDefaultCrispLauncher(true)
  }

  toggleHelp (event: Event) {
    this.$refs.helpPanel.toggle(event)
  }

  foldingCss () {
    return this.forceUnfold
      ? 'translate-x-0'
      : '-translate-x-layout-xl'
  }
}
