import { inject, nextTick, onBeforeUnmount, onMounted, ref, reactive } from 'vue'
import { formInjectionKey, IFormField } from './form-mixins'

export type ValidationRule<T> = boolean | ((value: T) => boolean)

export interface IValidatableInputOptions<T = unknown> {
  props: {
    readonly value: T,
    readonly required?: boolean | string,
    readonly rules?: ValidationRule<T> | ValidationRule<T>[]
    readonly ruleMessages?: string | string[]
    readonly disabled?: boolean
  }

  readonly isEmpty: boolean
}

export interface IValidatableInput extends IFormField {
  readonly invalid: boolean
  readonly errorMessage: string | null
  newInput(forceValidation: boolean): void
  blurred(): void
}

export function useValidation<T> (options: IValidatableInputOptions<T>): IValidatableInput {
  const invalid = ref(false)
  const errorMessage = ref('')

  function resetValidation () {
    invalid.value = false
    errorMessage.value = ''
  }

  function updateValidation () {
    resetValidation()

    const empty = options.isEmpty

    if (options.props.required && empty) {
      invalid.value = true
      errorMessage.value = typeof options.props.required === 'string' ? options.props.required : 'Veuillez remplir ce champ.' // TODO: i18n
      return
    }

    if (typeof options.props.rules !== 'undefined' && !empty) {
      const rules = Array.isArray(options.props.rules) ? options.props.rules : [options.props.rules]
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i]
        const result = typeof rule === 'boolean' ? rule : rule && rule(options.props.value)

        if (!result) {
          const ruleMessage = Array.isArray(options.props.ruleMessages) ? options.props.ruleMessages[i] : options.props.ruleMessages
          invalid.value = true
          errorMessage.value = ruleMessage || 'Ce champ est invalide.' // TODO: i18n, should trigger warning
          break
        }
      }
    }
  }

  function newInput (forceValidation = false) {
    if (forceValidation || invalid.value) {
      nextTick(() => updateValidation())
    }
  }

  function blurred () {
    nextTick(() => updateValidation())
  }

  const input = reactive({
    invalid,
    errorMessage,
    resetValidation,
    updateValidation,
    newInput,
    blurred
  })

  const form = inject(formInjectionKey)

  if (form) {
    onMounted(() => form.registerField(input))
    onBeforeUnmount(() => form.unregisterField(input))
  }

  return input
}
