/**
 * This module contains all utils related to session keys.
 */

import { encode as bufferToB64 } from 'base64-arraybuffer'
import type { PairingChallenge } from './graph'
import { SecondaryKeyUsage, type TgtInputType } from '~/graphql/types/accounts-public'
import {
  decryptKey,
  decryptKeyPair,
  derivePassword,
  encryptKey,
  encryptKeyPair,
  generateNewMasterKey,
  generateNewSecondaryKeyPair,
  generateSalt,
  signWithPrivateKey
} from '~/lib/zerauth/cryptography'
import { fetchCorrectedTimestamp } from '~/lib/zerauth/utils/remote-time'
import {
  CURRENT_ALGORITHM_VERSION,
  type EncryptedKey,
  type EncryptedKeyPair,
  type MasterKey,
  type NewSecondaryKey,
  type OpenKey,
  type OpenKeyPair,
  type SecondaryKey,
  type SessionKey,
  type SessionKeys,
  type UnvalidatedSessionKey
} from '~/lib/zerauth/key-types'
import uuidV4 from '~/lib/zerauth/utils/uuid-v4'
import { generateV1KeyPair } from '~/packages/crypto/nurne/src/primitives'
import type { KeyName } from '~/lib/zerauth/signature'

export interface NewSessionKeys {
  masterKey: OpenKey<MasterKey>
  sessionKey: OpenKeyPair<UnvalidatedSessionKey>
}

export async function generateNewKeys (): Promise<NewSessionKeys> {
  return {
    masterKey: {
      version: CURRENT_ALGORITHM_VERSION,
      saltB64: bufferToB64(generateSalt()),
      key: await generateNewMasterKey()
    },
    sessionKey: await generateNewSecondaryKey('accounts', SecondaryKeyUsage.Signature)
  }
}

export async function generateNewSecondaryKey<N extends KeyName, U extends SecondaryKeyUsage> (name: N, usage: U): Promise<OpenKeyPair<NewSecondaryKey<N, U>>> {
  const keyPair = await (usage === SecondaryKeyUsage.Nurne ? generateV1KeyPair(true) : generateNewSecondaryKeyPair())

  return {
    usage,
    name,
    keyPair,
    id: uuidV4(),
    version: CURRENT_ALGORITHM_VERSION
  }
}

export interface EncryptedNewSessionKeys {
  masterKey: EncryptedKey<MasterKey>
  sessionKey: EncryptedKeyPair<UnvalidatedSessionKey>
  passwordKey: CryptoKey
}

export async function encryptNewSessionKeys (sessionKeys: NewSessionKeys, password: string): Promise<EncryptedNewSessionKeys> {
  const passwordKey = await derivePasswordKey(sessionKeys.masterKey, password)

  return {
    masterKey: await encryptKey(sessionKeys.masterKey, passwordKey),
    sessionKey: await encryptKeyPair(sessionKeys.sessionKey, sessionKeys.masterKey.key),
    passwordKey
  }
}

export async function recoverKeys (pairingChallenge: PairingChallenge, password: string | CryptoKey): Promise<SessionKeys> {
  const masterKey = await decryptMasterKeyWithPassword(pairingChallenge.masterKey, password)

  return {
    masterKey,
    encryptedSessionKey: pairingChallenge.sessionKey,
    sessionKey: await decryptKey(pairingChallenge.sessionKey, masterKey.key, 'sign')
  }
}

interface MasterKeyChange {
  open: OpenKey<MasterKey>
  encrypted: EncryptedKey<MasterKey>
}

export async function changeMasterKeyPassword (oldEncryptedMasterKey: EncryptedKey<MasterKey>, oldPassword: string, newPassword: string): Promise<MasterKeyChange> {
  const newSaltB64 = bufferToB64(generateSalt())
  const newPasswordKey = await derivePassword(newPassword, newSaltB64)

  const decryptedMasterKey = await decryptMasterKeyWithPassword(oldEncryptedMasterKey, oldPassword, true)
  decryptedMasterKey.saltB64 = newSaltB64

  return {
    open: decryptedMasterKey,
    encrypted: await encryptKey(decryptedMasterKey, newPasswordKey)
  }
}

/**
 * Creates and signs a new TGT using the given Login Key.
 * @param loginKey The Login Key to sign the TGT with.
 */
export async function craftTgt (loginKey: OpenKey<SessionKey>): Promise<TgtInputType> {
  const timestamp = await fetchCorrectedTimestamp()
  const payload = `${loginKey.sessionKey}:${timestamp}`
  return {
    payload,
    signature: await signWithPrivateKey(loginKey.key, payload)
  }
}

export function decryptSecondaryKey<N extends KeyName, U extends SecondaryKeyUsage> (secondaryKey: EncryptedKeyPair<SecondaryKey<N, U>>, masterKey: OpenKey<MasterKey>): Promise<OpenKeyPair<SecondaryKey<N, U>>> {
  return decryptKeyPair(secondaryKey, masterKey.key, secondaryKey.usage === SecondaryKeyUsage.Nurne ? 'nurne' : 'sign', secondaryKey.usage === SecondaryKeyUsage.Nurne)
}

export function decryptSecondaryKeys (secondaryKeys: EncryptedKeyPair<SecondaryKey>[], masterKey: OpenKey<MasterKey>): Promise<OpenKeyPair<SecondaryKey>[]> {
  return Promise.all(secondaryKeys.map(sk => decryptSecondaryKey(sk, masterKey)))
}

/**
 * Derives a new cryptoKey from a given master key and password.
 * This function is exposed to avoid double-derivation in some code paths.
 *
 * @param masterKey The master key data.
 * @param password The password to derive the key from.
 */
export function derivePasswordKey (masterKey: MasterKey, password: string): Promise<CryptoKey> {
  return derivePassword(password, masterKey.saltB64)
}

// Utils

/**
 * Decrypts the master key with a potentially invalid user-provided password.
 * This is necessary for user-provided password forms to show a proper "invalid password" error instead of crashing on an OperationError.
 * @param encrypted The encrypted master key.
 * @param password The user-provided password.
 * @param extractable If the resulting key can be extracted (to be re-encrypted)
 * @throws Error with message 'WRONG_PASSWORD' if the password is incorrect.
 */
async function decryptMasterKeyWithPassword (encrypted: EncryptedKey<MasterKey>, password: string | CryptoKey, extractable = false): Promise<OpenKey<MasterKey>> {
  const passwordKey = typeof password === 'string' ? await derivePasswordKey(encrypted, password) : password

  let masterKey: OpenKey<MasterKey> | null = null
  try {
    masterKey = await decryptKey(encrypted, passwordKey, 'encrypt', extractable)
  } catch (e: any) {
    if (e.name === 'OperationError') { throw new Error('WRONG_PASSWORD') }
    throw e
  }

  return masterKey
}
