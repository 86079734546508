import { decode as b64ToBuffer, encode as bufferToB64 } from 'base64-arraybuffer'
import type { EncryptedPayload } from './types'
import type { JsonPublicKey } from './json'

export function generateV1KeyPair (extractable: boolean): Promise<CryptoKeyPair> {
  return window.crypto.subtle.generateKey({ name: 'ECDH', namedCurve: 'P-256' }, extractable, ['deriveBits'])
}

export function importV1PublicKey (publicKeyPayload: JsonPublicKey): Promise<CryptoKey> {
  if (publicKeyPayload.AlgorithmVersion !== 1) { throw new Error('Invalid algorithm version: ' + publicKeyPayload.AlgorithmVersion) }

  const publicKeyBuffer = b64ToBuffer(publicKeyPayload.PublicKey)
  return window.crypto.subtle.importKey('spki', publicKeyBuffer, { name: 'ECDH', namedCurve: 'P-256' }, false, [])
}

export async function exportV1PublicKey (publicKey: CryptoKey): Promise<string> {
  const key = await window.crypto.subtle.exportKey('spki', publicKey)
  return bufferToB64(key)
}

export async function exportV1PrivateKey (privateKey: CryptoKey): Promise<string> {
  const key = await window.crypto.subtle.exportKey('pkcs8', privateKey)
  return bufferToB64(key)
}

export async function deriveV1EncryptionKey (publicKey: CryptoKey, privateKey: CryptoKey): Promise<CryptoKey> {
  const bit = await window.crypto.subtle.deriveBits(
    { name: 'ECDH', public: publicKey },
    privateKey, 256)

  const hashed = await window.crypto.subtle.digest({ name: 'SHA-256' }, bit)

  return await window.crypto.subtle.importKey(
    'raw',
    hashed,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt'])
}

export async function encryptPayload (payload: ArrayBuffer, encryptionKey: CryptoKey): Promise<EncryptedPayload> {
  const iv = window.crypto.getRandomValues(new Uint8Array(12))
  const encrypted: ArrayBuffer = await window.crypto.subtle.encrypt({ name: 'AES-GCM', iv, tagLength: 128 }, encryptionKey, payload)

  // Tag is concatenated at the end, we must extract it
  const tagPos = encrypted.byteLength - 16 // 128 bits
  const cipher = encrypted.slice(0, tagPos)
  const tag = encrypted.slice(tagPos)

  return { cipher, iv, tag }
}
