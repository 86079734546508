// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./worker'
import type { IArgonMessageResult } from '~/lib/zerauth/argon2/worker'
import { encodeUrlB64 } from '~/lib/zerauth/utils/url-base64'

const argon2Worker = new Worker()
const workerRequests: Record<string, (hash: ArrayBuffer) => void> = {}
let lastWorkerMessageId = 0

argon2Worker.onmessage = ({ data: { hash, id } }: MessageEvent<IArgonMessageResult>) => {
  try {
    const resultHandler = workerRequests[id]
    if (!resultHandler) { throw new Error('Argon2 Worker: could not find result handler.') }
    resultHandler(hash)
  } finally {
    delete workerRequests[id]
  }
}

function safebtoa (data: string): string {
  try {
    // This is done to keep compatibility with current passwords, while still handling UTF8 properly
    return btoa(data)
  } catch (e) {
    if (e instanceof DOMException && e.name === 'InvalidCharacterError') {
      return encodeUrlB64(data)
    }
    throw e
  }
}

export function hash (password: string, saltB64: string, hashLength: number): Promise<ArrayBuffer> {
  if (atob(saltB64).length < 16) { throw new Error('Argon2 Worker: given salt is smaller than 16 bytes.') }

  return new Promise((resolve) => {
    const id = ++lastWorkerMessageId

    workerRequests[id] = resolve
    argon2Worker.postMessage({
      id,
      password: safebtoa(password),
      salt: saltB64,
      hashLength
    })
  })
}
