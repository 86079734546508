import { render, staticRenderFns } from "./table.vue?vue&type=template&id=2867f3dc"
var script = {}
import style0 from "./table.vue?vue&type=style&index=0&id=2867f3dc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports