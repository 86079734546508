
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class NuModal extends Vue {
  @Prop({ type: Boolean, default: false }) readonly opened!: boolean
  @Prop({ type: Boolean, default: false }) readonly big!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideCard!: boolean
  @Prop({ type: Boolean, default: false }) readonly sticky!: boolean
  @Prop({ type: String, default: '' }) readonly modalClass!: string

  get hasActions (): boolean {
    return !!this.$slots.actions
  }

  get activationClasses (): string {
    return (this.opened
      ? 'opacity-1 visible'
      : 'invisible opacity-0') + ' ' + this.modalClass
  }

  backdropClick (): void {
    if (!this.sticky) { this.$emit('update:opened', false) }
  }
}
