import { GraphQLClient } from 'graphql-request'
import { getSdk as accountsPublicSdk } from '~/graphql/types/accounts-public'
import { logWarningWithException } from 'nuag-core-utils/logging'

const ACCOUNTS_PUBLIC_URI = process.env.ACCOUNTS_URI! + '/public'
const accountsPublicClient = accountsPublicSdk(new GraphQLClient(ACCOUNTS_PUBLIC_URI))

interface CorrectedTime {
  localTimestamp: number
  timeDrift: number
}

let currentCorrectedTime: CorrectedTime | null = null

const MAX_CORRECTION_LIFETIME = 600 // 10 minutes

/**
 * Returns a corrected UNIX timestamp by calculating the drift between the local clock and the Accounts server.
 */
export async function fetchCorrectedTimestamp (): Promise<number> {
  const localTimestamp = Math.floor(Date.now() / 1000)

  // Cache
  if (currentCorrectedTime != null) {
    if (Math.abs(localTimestamp - currentCorrectedTime.localTimestamp) <= MAX_CORRECTION_LIFETIME) {
      return localTimestamp + currentCorrectedTime.timeDrift
    }
  }

  currentCorrectedTime = null

  try {
    const timeDrift = (await accountsPublicClient.timeDrift({ localTimestamp })).timeDrift
    currentCorrectedTime = { localTimestamp, timeDrift }
    return localTimestamp + timeDrift
  } catch (e) {
    logWarningWithException('CorrectedTimestamp', e, 'Failed to fetch time drift from server')
    return localTimestamp
  }
}
