import makeAuthenticatedFetch from './authenticated-fetch'
import {
  SecondaryKeyUsage,
  type SessionTokenType,
  type UserInputType
} from '~/graphql/types/accounts-public'
import {
  changeMasterKeyPassword,
  craftTgt,
  decryptSecondaryKey,
  decryptSecondaryKeys,
  encryptNewSessionKeys,
  generateNewKeys,
  generateNewSecondaryKey,
  recoverKeys
} from '~/lib/zerauth/session-keys'
import { createSecondaryKeyring } from '~/lib/zerauth/keyring'
import { logInfo, logWarningWithException } from 'nuag-core-utils/logging'
import { clearSessionStore, isPersisted, persistSession, retrieveSession } from '~/lib/zerauth/session-store'
import type { OpenKey, OpenKeyPair, SecondaryKey, SessionKey, SessionKeys } from '~/lib/zerauth/key-types'
import * as ZerauthGraph from '~/lib/zerauth/graph'
import { encryptKeyPair } from '~/lib/zerauth/cryptography'
import { craftSignedPayload, type KeyName } from '~/lib/zerauth/signature'
import { updateUser as updateUserCrisp } from 'nuag-core-utils/crisp'
import { delayedClearObservable, singleAsync } from '~/lib/async'
import { type IZerauthNurne, newZerauthNurne } from '~/lib/zerauth/nurne'

/**
 * The Zerauth-managed authenticated fetch function. It is meant to be used as a replacement for the standard fetch()
 * function, and works similarly but also automatically injects authentication headers and refreshes the session if
 * necessary.
 */
export const authenticatedFetch = makeAuthenticatedFetch({
  get sessionToken () {
    return getAuthenticatedZerauth().sessionToken
  },
  refreshSession () {
    return getAuthenticatedZerauth().refreshSession()
  }
})

/**
 * Exposed data about the current session.
 */
export interface ISessionInfo {
  /**
   * All user-related information, such as ID, display name, organizations, and so on.
   *
   * If you need to change the data populated here, you can update the `fetchMe` query in graphql/queries/accounts.gql.
   */
  me: Readonly<ZerauthGraph.UserInfo> | null
}

const sessionInfoObservable = delayedClearObservable<'me', Readonly<ZerauthGraph.UserInfo>>('me', updateUserCrisp)

/**
 * Exposes all the information regarding the current session.
 * This object is reactive, and therefore ready to be integrated in Vue pages right away, and will be updated shall
 * the current session's state change.
 */
export const sessionInfo: ISessionInfo = sessionInfoObservable.observable

export interface ILoggedOutZerauth {
  readonly loggedIn: false

  resetAccount (email: string, newPassword: string, resetCode: string): Promise<void>
  register (newPassword: string, user: UserInputType, sendMfa: boolean): Promise<void>
  newPairing (email: string, password: string, confirmationCode: string): Promise<void>
  newPairingFromSecret (email: string, password: string, authenticatingSecret: string): Promise<void>
}

export interface ILoggedInZerauth {
  readonly loggedIn: true
  readonly sessionId: string
  readonly sessionToken: string
  readonly userId: string

  readonly nurne: IZerauthNurne

  updateUser (): Promise<void>
  updatePassword (oldPassword: string, newPassword: string): Promise<void>
  logout (): Promise<void>
  refreshSession (): Promise<void>

  signPayload<T extends object> (keyName: KeyName, data: T): Promise<string>
}

export type IZerauth = ILoggedOutZerauth | ILoggedInZerauth;

function loggedOut (): ILoggedOutZerauth {
  sessionInfoObservable.clearLater()
  logInfo('Zerauth', 'Initialized, logged out')

  async function recoverSession (pairingChallenge: ZerauthGraph.PairingChallenge, password: string | CryptoKey): Promise<void> {
    const sessionKeys = await recoverKeys(pairingChallenge, password)

    const loggedIn = await performLogin(sessionKeys)
    await persistSession(sessionKeys)
    zerauth = loggedIn
    logInfo('Zerauth', `Completed new pairing for '${sessionInfo.me!.email}', session ID: '${loggedIn.sessionId}'`)
  }

  async function resetAccount (email: string, newPassword: string, resetCode: string): Promise<void> {
    const newKeys = await generateNewKeys()
    const encryptedNewKeys = await encryptNewSessionKeys(newKeys, newPassword)

    const challengeData = await ZerauthGraph.resetAccount(email, resetCode, encryptedNewKeys.masterKey, encryptedNewKeys.sessionKey)
    await clearSessionStore()
    await recoverSession(challengeData, encryptedNewKeys.passwordKey)
  }

  async function register (newPassword: string, user: UserInputType, sendMfa: boolean): Promise<void> {
    const newKeys = await generateNewKeys()
    const encryptedNewKeys = await encryptNewSessionKeys(newKeys, newPassword)

    await ZerauthGraph.register(user, encryptedNewKeys.masterKey, encryptedNewKeys.sessionKey, sendMfa)
  }

  interface IChallengeDataCacheEntry {
    email: string
    confirmationCode?: string
    authenticatingSecret?: string
    challengeData: ZerauthGraph.PairingChallenge
  }

  let challengeDataCache: IChallengeDataCacheEntry[] = []

  /**
   * Requests a new pairing from the Accounts server, which will open a new session.
   * This function will receive the encrypted Master key from the Accounts server and decrypt it with the given password.
   *
   * @param email The email address associated to the account the user wants to log into.
   * @param password The password used to encrypt the account's master key.
   * @param confirmationCode The confirmation code the user received via email, that was sent using the requestPairing mutation.
   */
  async function newPairing (email: string, password: string, confirmationCode: string): Promise<void> {
    let challengeData = challengeDataCache.find(c => c.email === email && c.confirmationCode === confirmationCode)?.challengeData
    if (!challengeData) {
      challengeData = await ZerauthGraph.newPairing(email, confirmationCode)
      challengeDataCache.push({ email, confirmationCode, challengeData })
    }

    await recoverSession(challengeData, password)
    challengeDataCache = []
  }

  async function newPairingFromSecret (email: string, password: string, authenticatingSecret: string): Promise<void> {
    let challengeData = challengeDataCache.find(c => c.email === email && c.authenticatingSecret === authenticatingSecret)?.challengeData
    if (!challengeData) {
      challengeData = await ZerauthGraph.newPairingFromSecret(email, authenticatingSecret)
      challengeDataCache.push({ email, authenticatingSecret, challengeData })
    }
    await recoverSession(challengeData, password)
    challengeDataCache = []
  }

  return {
    loggedIn: false,
    resetAccount,
    register,
    newPairing,
    newPairingFromSecret
  }
}

let zerauth: IZerauth | null = null

export function getZerauth () { return zerauth }

export function getAuthenticatedZerauth (): ILoggedInZerauth {
  if (!zerauth?.loggedIn) { throw new Error('Not authenticated') }
  return zerauth
}

export function getLoggedoutZerauth (): ILoggedOutZerauth {
  if (!zerauth) { throw new Error('Zerauth was not initialized yet') }
  if (zerauth.loggedIn) { throw new Error('Already logged in') }
  return zerauth
}

/**
 * Ensures that Zerauth is properly initialized, i.e. this will try to load an already existing session from browser
 * storage, and refresh tokens and update the session info accordingly.
 *
 * If no session has been found in browser storage, this returns false.
 * This function does nothing if it has already been called once, whether a session was retrieved or not.
 *
 * @return true if an existing session has been loaded, false otherwise.
 */
export async function getInitializedZerauth (): Promise<IZerauth> {
  if (!zerauth) {
    zerauth = await initializeZerauth()
  }

  return zerauth
}

async function initializeZerauth (): Promise<IZerauth> {
  let sessionKeys: SessionKeys | null = null

  try {
    sessionKeys = await retrieveSession()
  } catch (e) {
    logWarningWithException('Zerauth', e, 'Failed to retrieve keys')
    await clearSessionStore() // Session store is probably corrupted - no need to keep it
  }

  if (!sessionKeys) { return loggedOut() }

  try {
    const loggedIn = await performLogin(sessionKeys)
    logInfo('Zerauth', `Initialized, currently logged in as '${sessionInfo.me!.email}', session ID: '${loggedIn.sessionId}`)
    return loggedIn
  } catch (e) {
    logWarningWithException('Zerauth', e, 'Failed to refresh session')
    return loggedOut()
  }
}

async function performLogin (sessionKeys: SessionKeys): Promise<ILoggedInZerauth> {
  // Initial session refresh
  let currentSession = await performSessionRefresh(sessionKeys.sessionKey)
  const refreshSession = singleAsync(async () => { currentSession = await performSessionRefresh(sessionKeys.sessionKey) })

  // Fetch user data
  const authenticatedFetch = makeAuthenticatedFetch({
    get sessionToken () { return currentSession.token },
    refreshSession: async () => { await refreshSession.perform() }
  })
  const authenticatedGraph = ZerauthGraph.newAuthenticatedZerauthGraph(authenticatedFetch)
  let userData = await authenticatedGraph.fetchUserData()
  sessionInfoObservable.set(Object.freeze(userData.userInfo))

  // load secondary keyring
  const secondaryKeyring = createSecondaryKeyring()
  await secondaryKeyring.clearAndLoadAll(await decryptSecondaryKeys(userData.secondaryKeys, sessionKeys.masterKey))

  await ensureSecondaryKeyExists('accounts_tur', SecondaryKeyUsage.Turones)
  await ensureSecondaryKeyExists('nuag_tur', SecondaryKeyUsage.Turones)

  // Session lifetime

  async function updateUser (): Promise<void> {
    await refreshSession.perform()
    userData = await authenticatedGraph.fetchUserData()
    sessionInfoObservable.set(Object.freeze(userData.userInfo))
  }

  async function logout (): Promise<void> {
    try {
      await authenticatedGraph.deleteSession(currentSession.sessionId)
    } catch (e) {
      logWarningWithException('Zerauth', e, 'Server logout failed, still dropping session')
    }

    await clearSessionStore()
    logInfo('Zerauth', 'User logged out.')
    zerauth = loggedOut()
  }

  async function updatePassword (oldPassword: string, newPassword: string): Promise<void> {
    const oldMasterKey = await authenticatedGraph.fetchMasterKey()
    const masterKeys = await changeMasterKeyPassword(oldMasterKey, oldPassword, newPassword)
    await authenticatedGraph.updateMasterKey(masterKeys.encrypted)
    sessionKeys.masterKey = masterKeys.open

    if (await isPersisted()) {
      await persistSession(sessionKeys)
    }
  }

  // Signature functions

  async function signPayload<T extends object> (keyName: KeyName, data: T): Promise<string> {
    const userId = currentSession.userId

    await ensureSecondaryKeyExists(keyName, SecondaryKeyUsage.Turones)
    return craftSignedPayload(userId, data, input => secondaryKeyring.signPayload(keyName, input))
  }

  function ensureSecondaryKeyExists<N extends KeyName, U extends SecondaryKeyUsage> (keyName: N, usage: U): Promise<OpenKeyPair<SecondaryKey<N, U>>> {
    return secondaryKeyring.ensureExists(keyName, usage, (n, u) => fetchOrGenerateSecondaryKey(n, u))
  }

  async function fetchOrGenerateSecondaryKey<N extends KeyName, U extends SecondaryKeyUsage> (keyName: N, usage: U): Promise<OpenKeyPair<SecondaryKey<N, U>>> {
    const existingSecondaryKey = await authenticatedGraph.fetchSecondaryKey(keyName, usage)
    if (existingSecondaryKey) {
      return decryptSecondaryKey(existingSecondaryKey, sessionKeys.masterKey)
    }

    const newSecondaryKey = await generateNewSecondaryKey(keyName, usage)
    const encryptedSecondaryKey = await encryptKeyPair(newSecondaryKey, sessionKeys.masterKey.key)

    await authenticatedGraph.addSecondaryKey(encryptedSecondaryKey)

    return newSecondaryKey
  }

  return {
    loggedIn: true,
    nurne: newZerauthNurne(secondaryKeyring, authenticatedGraph, sessionKeys, ensureSecondaryKeyExists),

    get sessionId () { return currentSession.sessionId },
    get sessionToken () { return currentSession.token },
    get userId () { return currentSession.userId },

    updateUser,
    updatePassword,
    logout,
    refreshSession (): Promise<void> {
      return refreshSession.perform()
    },

    signPayload
  }
}

// Internal stuff

async function performSessionRefresh (sessionKey: OpenKey<SessionKey>): Promise<SessionTokenType> {
  const tgt = await craftTgt(sessionKey)
  return await ZerauthGraph.refreshSession(tgt)
}
