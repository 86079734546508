import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Long: any;
};

export type BasicUserType = {
  country: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type ChallengeDataType = {
  accountsKeyIv: Scalars['String'];
  algorithmVersion: Scalars['Int'];
  encryptedAccountsKey: Scalars['String'];
  encryptedMasterKey: Scalars['String'];
  masterKeyIv: Scalars['String'];
  salt: Scalars['String'];
  sessionKey: Scalars['String'];
};

export type MasterKeyInputType = {
  algorithmVersion: Scalars['Int'];
  encryptedKey: Scalars['String'];
  iv: Scalars['String'];
  salt: Scalars['String'];
};

export type PublicMutation = {
  newPairing: ChallengeDataType;
  newPairingFromSecret: ChallengeDataType;
  refreshSession: SessionTokenType;
  register: BasicUserType;
  requestAccountReset: Scalars['Boolean'];
  requestPairing: Scalars['Boolean'];
  resetAccount: ChallengeDataType;
};


export type PublicMutationNewPairingArgs = {
  confirmationToken: Scalars['String'];
  email: Scalars['String'];
};


export type PublicMutationNewPairingFromSecretArgs = {
  authenticatingSecret: Scalars['String'];
  email: Scalars['String'];
};


export type PublicMutationRefreshSessionArgs = {
  tgt: TgtInputType;
};


export type PublicMutationRegisterArgs = {
  accountsKey: SecondaryKeyInputType;
  masterKey: MasterKeyInputType;
  sendMfa: Scalars['Boolean'];
  user: UserInputType;
};


export type PublicMutationRequestAccountResetArgs = {
  email: Scalars['String'];
};


export type PublicMutationRequestPairingArgs = {
  email: Scalars['String'];
};


export type PublicMutationResetAccountArgs = {
  accountsKey: SecondaryKeyInputType;
  email: Scalars['String'];
  masterKey: MasterKeyInputType;
  resetCode: Scalars['String'];
};

export type PublicQuery = {
  timeDrift: Scalars['Long'];
};


export type PublicQueryTimeDriftArgs = {
  localTimestamp: Scalars['Long'];
};

export type SecondaryKeyInputType = {
  algorithmVersion: Scalars['Int'];
  encryptedKey: Scalars['String'];
  id: Scalars['ID'];
  iv: Scalars['String'];
  name: Scalars['String'];
  publicKey: Scalars['String'];
  usage?: InputMaybe<SecondaryKeyUsage>;
};

export enum SecondaryKeyUsage {
  Derivation = 'DERIVATION',
  Encryption = 'ENCRYPTION',
  Hashing = 'HASHING',
  Nurne = 'NURNE',
  Signature = 'SIGNATURE',
  Turones = 'TURONES',
  Verification = 'VERIFICATION'
}

export type SessionTokenType = {
  expiresAt: Scalars['DateTime'];
  orgId: Array<Scalars['ID']>;
  sessionId: Scalars['ID'];
  token: Scalars['String'];
  userId: Scalars['ID'];
};

export type TgtInputType = {
  payload: Scalars['String'];
  signature: Scalars['String'];
};

export type UserInputType = {
  commercialSolicitations: Scalars['Boolean'];
  country: Scalars['String'];
  email: Scalars['String'];
  emailNotifications: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  preferredLocale: Scalars['String'];
  tosAccepted: Scalars['Boolean'];
};

export type RequestPairingMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPairingMutation = { requestPairing: boolean };

export type RequestAccountResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestAccountResetMutation = { requestAccountReset: boolean };

export type NewPairingMutationVariables = Exact<{
  email: Scalars['String'];
  confirmationToken: Scalars['String'];
}>;


export type NewPairingMutation = { newPairing: { accountsKeyIv: string, algorithmVersion: number, encryptedAccountsKey: string, encryptedMasterKey: string, masterKeyIv: string, salt: string, sessionKey: string } };

export type RegisterMutationVariables = Exact<{
  user: UserInputType;
  accountsKey: SecondaryKeyInputType;
  masterKey: MasterKeyInputType;
  sendMfa: Scalars['Boolean'];
}>;


export type RegisterMutation = { register: { id: string, firstName: string, lastName: string } };

export type ResetAccountMutationVariables = Exact<{
  email: Scalars['String'];
  resetCode: Scalars['String'];
  accountsKey: SecondaryKeyInputType;
  masterKey: MasterKeyInputType;
}>;


export type ResetAccountMutation = { resetAccount: { accountsKeyIv: string, algorithmVersion: number, encryptedAccountsKey: string, encryptedMasterKey: string, masterKeyIv: string, salt: string, sessionKey: string } };

export type TimeDriftQueryVariables = Exact<{
  localTimestamp: Scalars['Long'];
}>;


export type TimeDriftQuery = { timeDrift: any };

export type RefreshSessionMutationVariables = Exact<{
  tgt: TgtInputType;
}>;


export type RefreshSessionMutation = { refreshSession: { expiresAt: any, orgId: Array<string>, sessionId: string, token: string, userId: string } };

export type NewPairingFromSecretMutationVariables = Exact<{
  email: Scalars['String'];
  authenticatingSecret: Scalars['String'];
}>;


export type NewPairingFromSecretMutation = { newPairingFromSecret: { accountsKeyIv: string, algorithmVersion: number, encryptedAccountsKey: string, encryptedMasterKey: string, masterKeyIv: string, salt: string, sessionKey: string } };


export const RequestPairingDocument = gql`
    mutation requestPairing($email: String!) {
  requestPairing(email: $email)
}
    `;
export const RequestAccountResetDocument = gql`
    mutation requestAccountReset($email: String!) {
  requestAccountReset(email: $email)
}
    `;
export const NewPairingDocument = gql`
    mutation newPairing($email: String!, $confirmationToken: String!) {
  newPairing(email: $email, confirmationToken: $confirmationToken) {
    accountsKeyIv
    algorithmVersion
    encryptedAccountsKey
    encryptedMasterKey
    masterKeyIv
    salt
    sessionKey
  }
}
    `;
export const RegisterDocument = gql`
    mutation register($user: UserInputType!, $accountsKey: SecondaryKeyInputType!, $masterKey: MasterKeyInputType!, $sendMfa: Boolean!) {
  register(
    user: $user
    accountsKey: $accountsKey
    masterKey: $masterKey
    sendMfa: $sendMfa
  ) {
    id
    firstName
    lastName
  }
}
    `;
export const ResetAccountDocument = gql`
    mutation resetAccount($email: String!, $resetCode: String!, $accountsKey: SecondaryKeyInputType!, $masterKey: MasterKeyInputType!) {
  resetAccount(
    email: $email
    resetCode: $resetCode
    accountsKey: $accountsKey
    masterKey: $masterKey
  ) {
    accountsKeyIv
    algorithmVersion
    encryptedAccountsKey
    encryptedMasterKey
    masterKeyIv
    salt
    sessionKey
  }
}
    `;
export const TimeDriftDocument = gql`
    query timeDrift($localTimestamp: Long!) {
  timeDrift(localTimestamp: $localTimestamp)
}
    `;
export const RefreshSessionDocument = gql`
    mutation refreshSession($tgt: TgtInputType!) {
  refreshSession(tgt: $tgt) {
    expiresAt
    orgId
    sessionId
    token
    userId
  }
}
    `;
export const NewPairingFromSecretDocument = gql`
    mutation newPairingFromSecret($email: String!, $authenticatingSecret: String!) {
  newPairingFromSecret(email: $email, authenticatingSecret: $authenticatingSecret) {
    accountsKeyIv
    algorithmVersion
    encryptedAccountsKey
    encryptedMasterKey
    masterKeyIv
    salt
    sessionKey
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    requestPairing(variables: RequestPairingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestPairingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestPairingMutation>(RequestPairingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'requestPairing', 'mutation');
    },
    requestAccountReset(variables: RequestAccountResetMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestAccountResetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestAccountResetMutation>(RequestAccountResetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'requestAccountReset', 'mutation');
    },
    newPairing(variables: NewPairingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NewPairingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<NewPairingMutation>(NewPairingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'newPairing', 'mutation');
    },
    register(variables: RegisterMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RegisterMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RegisterMutation>(RegisterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'register', 'mutation');
    },
    resetAccount(variables: ResetAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetAccountMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetAccountMutation>(ResetAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resetAccount', 'mutation');
    },
    timeDrift(variables: TimeDriftQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TimeDriftQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TimeDriftQuery>(TimeDriftDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'timeDrift', 'query');
    },
    refreshSession(variables: RefreshSessionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RefreshSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RefreshSessionMutation>(RefreshSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'refreshSession', 'mutation');
    },
    newPairingFromSecret(variables: NewPairingFromSecretMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NewPairingFromSecretMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<NewPairingFromSecretMutation>(NewPairingFromSecretDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'newPairingFromSecret', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;