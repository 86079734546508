import Button from 'primevue/button'
import Listbox from 'primevue/listbox'
import Card from 'primevue/card'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Checkbox from 'primevue/checkbox'
import Radiobutton from 'primevue/radiobutton'
import Divider from 'primevue/divider'
import Message from 'primevue/message'
import Chip from 'primevue/chip'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import FileUpload from 'primevue/fileupload'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import Calendar from 'primevue/calendar'
import ToggleButton from 'primevue/togglebutton'
import AutoComplete from 'primevue/autocomplete'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import Badge from 'primevue/badge'
import Tag from 'primevue/tag'
import Panel from 'primevue/panel'
import Textarea from 'primevue/textarea'
import Timeline from 'primevue/timeline'
import Editor from 'primevue/editor'
import ImagePreview from 'primevue/imagepreview'
import InputMask from 'primevue/inputmask'
import ContextMenu from 'primevue/contextmenu'
import OverlayPanel from 'primevue/overlaypanel'
import Slider from 'primevue/slider'
import Sidebar from 'primevue/sidebar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Paginator from 'primevue/paginator'

import Ripple from 'primevue/ripple'
import Tooltip from 'primevue/tooltip'

import type { NuxtApp } from '@nuxt/types/app'
import type { VueConstructor } from 'vue'

export type ContextMenuItem = {
  label: string | (() => string)
  icon?: string
  to?: string
  command?: () => (void | Promise<void>)
  url?: string
  items?: ContextMenuItem[]
  disabled?: boolean | (() => boolean)
  visible?: boolean | (() => boolean)
  target?: string
  style?: object
  class?: string
} | { separator: true }

export default function usePrimeVue (nuxtApp: NuxtApp & { vueApp: VueConstructor }) {
  // nuxtApp.vueApp.use(PrimeVue, { ripple: true })

  nuxtApp.vueApp.directive('ripple', Ripple)
  nuxtApp.vueApp.directive('tooltip', Tooltip)

  nuxtApp.vueApp.component('Button', Button)
  nuxtApp.vueApp.component('Listbox', Listbox)
  nuxtApp.vueApp.component('DataTable', DataTable)
  nuxtApp.vueApp.component('Column', Column)
  nuxtApp.vueApp.component('FileUpload', FileUpload)
  nuxtApp.vueApp.component('InputText', InputText)
  nuxtApp.vueApp.component('InputNumber', InputNumber)
  nuxtApp.vueApp.component('InputSwitch', InputSwitch)
  nuxtApp.vueApp.component('Calendar', Calendar)
  nuxtApp.vueApp.component('ToggleButton', ToggleButton)
  nuxtApp.vueApp.component('AutoComplete', AutoComplete)
  nuxtApp.vueApp.component('Card', Card)
  nuxtApp.vueApp.component('Accordion', Accordion)
  nuxtApp.vueApp.component('AccordionTab', AccordionTab)
  nuxtApp.vueApp.component('Checkbox', Checkbox)
  nuxtApp.vueApp.component('RadioButton', Radiobutton)
  nuxtApp.vueApp.component('Divider', Divider)
  nuxtApp.vueApp.component('Message', Message)
  nuxtApp.vueApp.component('Chip', Chip)
  nuxtApp.vueApp.component('Dialog', Dialog)
  nuxtApp.vueApp.component('ProgressSpinner', ProgressSpinner)
  nuxtApp.vueApp.component('Badge', Badge)
  nuxtApp.vueApp.component('Tag', Tag)
  nuxtApp.vueApp.component('Panel', Panel)
  nuxtApp.vueApp.component('Textarea', Textarea)
  nuxtApp.vueApp.component('Timeline', Timeline)
  nuxtApp.vueApp.component('Editor', Editor)
  nuxtApp.vueApp.component('ImagePreview', ImagePreview)
  nuxtApp.vueApp.component('InputMask', InputMask)
  nuxtApp.vueApp.component('ContextMenu', ContextMenu)
  nuxtApp.vueApp.component('OverlayPanel', OverlayPanel)
  nuxtApp.vueApp.component('Slider', Slider)
  nuxtApp.vueApp.component('Sidebar', Sidebar)
  nuxtApp.vueApp.component('TabView', TabView)
  nuxtApp.vueApp.component('TabPanel', TabPanel)
  nuxtApp.vueApp.component('Paginator', Paginator)
}
