import { ClientError } from 'graphql-request'

export interface IActionError {
  raw?: Error | ClientError
  /**
   * The GraphQL error code, if any
   */
  errorCode?: string
}

export function getActionError (raw: unknown): IActionError {
  const errors = raw instanceof ClientError ? raw.response.errors : undefined
  const errorCode = errors && (((errors[0] as any)?.extensions?.code) || errors[0]?.message)

  return { raw: raw instanceof ClientError || raw instanceof Error ? raw : undefined, errorCode }
}
