import bell from './bell.png'
import grow from './grow.png'
import learn from './learn.png'
import party from './party.png'
import redFlag from './red-flag.png'
import rocket from './rocket.png'
import hourglass from './hourglass.png'
import vote from './vote.png'

const emojis = {
  bell,
  grow,
  learn,
  party,
  redFlag,
  rocket,
  hourglass,
  vote
} as const

export default emojis

// These colors are used when the emoji is used against a neutral context
// Against a colourful context, the emoji must always appear with a white background
export type EmojiBackgroundColors = 'red' | 'yellow' | 'green' | 'blue' | 'primary'
export const backgroundColors: Record<keyof typeof emojis, EmojiBackgroundColors> = {
  bell: 'yellow',
  grow: 'green',
  learn: 'yellow',
  party: 'green',
  redFlag: 'red',
  rocket: 'blue',
  hourglass: 'red',
  vote: 'blue'
}
