/* eslint-disable no-console */
import { captureException } from '@sentry/core'
import { CrispEvent, registerEvent } from './crisp'

function formatMessage (serviceName: string, message: string): string {
  return `[${serviceName}] ${message}`
}

export function logInfo (serviceName: string, message: string, ...extra: unknown[]): void {
  const logged = formatMessage(serviceName, message)
  console.info(logged, ...extra)
  registerEvent(CrispEvent.Info, logged, extra)
}

export function logWarning (serviceName: string, message: string, ...extra: unknown[]): void {
  const logged = formatMessage(serviceName, message)
  console.warn(logged, ...extra)
  registerEvent(CrispEvent.Warning, logged, extra)
}

export function logWarningWithException (serviceName: string, exception: unknown | undefined, message: string, ...extra: unknown[]): void {
  const logged = formatMessage(serviceName, message)
  console.warn(logged, ...extra, '\n', exception)
  if (exception instanceof Error) {
    registerEvent(CrispEvent.Warning, logged, [exception.message, ...extra])
  } else {
    registerEvent(CrispEvent.Warning, logged, [exception, ...extra])
  }
}

export function loggerWarning (serviceName: string, message: string): (e: unknown) => void {
  return e => logWarningWithException(serviceName, e, message)
}

export function logError (serviceName: string, exception: unknown | undefined, message: string, ...extra: unknown[]): void {
  try {
    const logged = formatMessage(serviceName, message)
    captureException(exception, {
      extra: { serviceName, extra, message }
    })
    console.error(logged, ...extra, '\n', exception)
    if (exception instanceof Error) {
      registerEvent(CrispEvent.Error, logged, [exception.message, ...extra])
    } else {
      registerEvent(CrispEvent.Error, logged, [exception, ...extra])
    }
  } catch (e) {
    console.error(serviceName, e, exception, message, 'failed to log error', extra)
  }
}

export function loggerError (serviceName: string, message: string, ...extra: unknown[]): (e: unknown) => void {
  return e => logError(serviceName, e, message, ...extra)
}
