import { GraphQLClient } from 'graphql-request'

import { getSdk as accountsPublicSdk } from './types/accounts-public'
import { getSdk as nuagPublicSdk } from './types/nuag-public'
import { getSdk as accountsPrivateSdk } from '~/graphql/types/accounts'
import { getSdk as nuagPrivateSdk } from '~/graphql/types/nuag'
import { authenticatedFetch } from '~/lib/zerauth'

const ACCOUNTS_PRIVATE_URI = process.env.ACCOUNTS_URI! + '/private'
const ACCOUNTS_PUBLIC_URI = process.env.ACCOUNTS_URI! + '/public'

const NUAG_PRIVATE_URI = process.env.API_URI! + '/private'
const NUAG_PUBLIC_URI = process.env.API_URI! + '/public'

// Accounts Public - Graph Client
export const accountsPublicClient = accountsPublicSdk(new GraphQLClient(ACCOUNTS_PUBLIC_URI))

export const accountsPrivateClient = accountsPrivateSdk(new GraphQLClient(ACCOUNTS_PRIVATE_URI, {
  credentials: 'include',
  fetch: authenticatedFetch
}))

export const nuagPublicClient = nuagPublicSdk(new GraphQLClient(NUAG_PUBLIC_URI))

export const nuagPrivateClient = nuagPrivateSdk(new GraphQLClient(NUAG_PRIVATE_URI, {
  credentials: 'include',
  fetch: authenticatedFetch
}))
