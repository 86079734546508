export async function playSoftAudio (fileName: string): Promise<void> {
  const audio = new Audio(fileName)
  audio.muted = false
  audio.volume = 0.4
  try {
    await audio.play()
  } catch (e) {
    if (!(e instanceof DOMException)) { throw e } // User agent disallowed sound for some reason, not an issue
  }
}
