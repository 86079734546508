import Vue from 'vue'
import { Bar, Doughnut, Line, Pie } from 'vue-chartjs'
import { defaults } from 'chart.js'

const registerComponent = function (name, originalComponent) {
  Vue.component(
    name,
    {
      extends: originalComponent,
      props: {
        data: {
          type: Object,
          required: true
        },
        options: {
          type: Object,
          required: false,
          default: null
        }
      },
      watch: {
        data: function () { this.renderChart(this.data, this.options) },
        options: function () { this.renderChart(this.data, this.options) }
      },
      mounted () {
        this.renderChart(this.data, this.options)
      }
    }
  )
}

defaults.doughnut.cutoutPercentage = 80
defaults.doughnut.borderWidth = 0

registerComponent('BarChart', Bar)
registerComponent('DoughnutChart', Doughnut)
registerComponent('LineChart', Line)
registerComponent('PieChart', Pie)
