import { render, staticRenderFns } from "./default-v3.vue?vue&type=template&id=30787e22"
import script from "./default-v3.vue?vue&type=script&setup=true&lang=ts"
export * from "./default-v3.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./default-v3.vue?vue&type=style&index=0&id=30787e22&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./default-v3.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonTopBar: require('/home/vsts/work/1/s/components/common/top-bar.vue').default,NuAvatar: require('/home/vsts/work/1/s/components/nu/avatar.vue').default,CommonSidebarButton: require('/home/vsts/work/1/s/components/common/sidebar-button.vue').default,CommonSidebarSection: require('/home/vsts/work/1/s/components/common/sidebar-section.vue').default,CommonSidebar: require('/home/vsts/work/1/s/components/common/sidebar.vue').default,CommonNotifications: require('/home/vsts/work/1/s/components/common/notifications.vue').default,CommonModalContainer: require('/home/vsts/work/1/s/components/common/modal-container.vue').default})
