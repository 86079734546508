
import { Component, Vue } from 'nuxt-property-decorator'
import { modalGuardStatus } from '~/middleware/modal-guard'

@Component
export default class ModalGuard extends Vue {
  get open () {
    return modalGuardStatus.triggered
  }

  set open (value: boolean) {
    modalGuardStatus.triggered = value
  }
}
