import { Vue } from 'nuxt-property-decorator'
import { playSoftAudio } from '~/lib/audio'
import * as Logging from 'nuag-core-utils/logging'

export interface Notification {
  /** Title of the notification displayed in bold */
  title: string,

  /** Content of the notification (should stay short) */
  content: string,

  /** Type (color) used for this notification */
  type: 'information' | 'success' | 'critical'

  /** Sound file to play on notification display, if any **/
  sound?: string
}

export interface NotificationsState {
  /** Currently displayed notifications */
  list: Notification[]
}

export const notifications: NotificationsState = Vue.observable({
  list: []
})

/**
 * Displays a notification for a given amount of time
 * @param notif The notification to display
 * @param seconds The amount of time (in seconds) for which the notification is displayed
 */
export function pushNotification (notif: Notification, seconds = 10) {
  notifications.list.push(notif)
  if (notif.sound) { playSoftAudio(notif.sound).catch(e => Logging.logWarningWithException('notifications', e, `Could not play sound file: ${notif.sound}`)) }

  setTimeout(
    () => {
      notifications.list.splice(notifications.list.indexOf(notif), 1)
    },
    seconds * 1000
  )
}
