import Vue from 'vue'

export interface ISingleAsync<T> {
  perform (): Promise<T>
}

export function singleAsync<T> (callback: () => Promise<T>): ISingleAsync<T> {
  let currentPromise: Promise<T> | null = null

  return {
    async perform (): Promise<T> {
      if (!currentPromise) {
        const value = await (currentPromise = callback())
        currentPromise = null
        return value
      } else {
        return await currentPromise
      }
    }
  }
}

export interface IDelayedClearObservable<K extends string, T> {
  set(value: T): void
  clearLater(): void
  clearNow(): void

  readonly observable: { [_ in K]: T | null }
}

const SESSION_USER_CLEAR_TIMEOUT = 500
export function delayedClearObservable<K extends string, T> (key: K, onChanged: (v: T | null) => void): IDelayedClearObservable<K, T> {
  const observable = Vue.observable({ [key]: null } as { [_ in K]: T | null })
  let clearingTimeout: NodeJS.Timeout | null = null

  function clearNow () {
    observable[key] = null
    onChanged(null)
  }

  return {
    observable,
    set (value: T) {
      observable[key] = value
      onChanged(value)
      if (clearingTimeout) {
        clearTimeout(clearingTimeout)
        clearingTimeout = null
      }
    },
    clearNow,
    clearLater () {
      if (clearingTimeout) {
        clearTimeout(clearingTimeout)
      }
      clearingTimeout = setTimeout(clearNow, SESSION_USER_CLEAR_TIMEOUT)
    }
  }
}

type PromisifyInnerCb<T> = (error?: unknown | null, data?: T) => void
export function promisify<T> (callback: (cb: PromisifyInnerCb<T>) => void): Promise<T> {
  return new Promise((resolve, reject) => {
    const innerCb = (error?: unknown | null, data?: T) => { if (!data) { reject(error) } else { resolve(data) } }
    callback(innerCb)
  })
}
