
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { cdnFileUrl } from '~/lib/file'

export type AvatarSize = 'small' | 'medium' | 'intermediate' | 'large' | 'xlarge' | 'xxlarge'
export type AvatarType = 'user' | 'organization'

export const selfCount = Vue.observable({ count: 1 })
export function refreshSelf (): void {
  selfCount.count += 1
}

@Component
export default class NuAvatar extends Vue {
  @Prop(String) readonly id?: string
  @Prop(String) readonly type?: AvatarType
  @Prop(String) readonly url?: string
  @Prop(String) readonly name!: string
  @Prop({ type: String, default: 'large' }) readonly size!: AvatarSize
  @Prop({ type: Boolean, default: false }) readonly colored!: boolean
  @Prop({ type: Boolean, default: false }) readonly self!: boolean

  get imageUrl (): string {
    let filePath = `avatars/${this.type}s/${this.id}`
    if (this.self) {
      filePath += `?${selfCount.count}`
    }
    const url = this.url || cdnFileUrl(filePath)
    return `background-image: url(${url})`
  }

  get avatarSize (): string[] {
    switch (this.size) {
      case 'small':
        return ['w-sm', 'h-sm']
      case 'medium':
        return ['w-md', 'h-md']
      case 'intermediate':
        return ['w-it', 'h-it']
      case 'large':
        return ['w-lg', 'h-lg']
      case 'xlarge':
        return ['w-xl', 'h-xl']
      case 'xxlarge':
        return ['w-xxl', 'h-xxl']
    }
  }

  get containerClasses (): string[] {
    return (this.colored
      ? ['bg-primary-contrast']
      : ['bg-white', 'bg-opacity-20']).concat(this.avatarSize)
  }

  get displayedName (): string {
    const initials = this.name.split(' ').map(p => p[0]!)
    return (initials.length > 3
      ? initials.slice(0, 3)
      : initials).join('')
  }
}
