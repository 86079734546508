const DB_TIMEOUT = 4000

export interface NuIndexedDB {
  add<T>(key: string, value: T): Promise<void>,
  get(key: string): Promise<unknown>
  remove(key: string): Promise<void>
  clear(): Promise<void>
  close(): void
}

export function openIndexedDb (dbName: string, dbVersion: number, objectStoreName: string): Promise<NuIndexedDB> {
  function performTransaction<T> (db: IDBDatabase, mode: IDBTransactionMode, action: (store: IDBObjectStore) => IDBRequest<T>): Promise<T> {
    const transaction = db.transaction([objectStoreName], mode)
    const store = transaction.objectStore(objectStoreName)
    const request = action(store)

    const execution = new Promise<T>((resolve, reject) => {
      transaction.oncomplete = () => resolve(request.result)
      transaction.onerror = () => reject(new Error(request.error?.message || 'indexedDB transaction failed'))
    })

    // eslint-disable-next-line promise/param-names
    const timeoutPromise = new Promise<T>((_, reject) => setTimeout(() => reject(new Error('indexedDB transaction timed out')), DB_TIMEOUT))
    return Promise.race([execution, timeoutPromise])
  }

  return new Promise<NuIndexedDB>((resolve, reject) => {
    const openRequest = indexedDB.open(dbName, dbVersion)
    openRequest.onerror = (_ev) => {
      reject(new Error('Failed to open indexedDB'))
    }

    openRequest.onupgradeneeded = () => {
      const db = openRequest.result
      db.createObjectStore(objectStoreName)
    }

    openRequest.onsuccess = () => {
      const db = openRequest.result
      resolve({
        add: async (key, value) => { await performTransaction(db, 'readwrite', s => s.add(value, key)) },
        get: key => performTransaction(db, 'readonly', s => s.get(key)),
        remove: key => performTransaction(db, 'readwrite', s => s.delete(key)),
        clear: () => performTransaction(db, 'readwrite', s => s.clear()),
        close: () => db.close()
      })
    }
  })
}

export function destroyIndexedDb (dbName: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(dbName)

    deleteRequest.onerror = (_ev) => {
      reject(new Error('Failed to open indexedDB'))
    }

    deleteRequest.onsuccess = () => resolve()
  })
}
