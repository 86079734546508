import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCheck,
  faEllipsisH,
  faLifeRing,
  faSpinnerThird,
  faChevronRight,
  faCamera,
  faUser,
  faEye,
  faEyeSlash,
  faBell,
  faCaretUp,
  faCaretDown,
  faEnvelope,
  faMailbox,
  faPen,
  faSortUp,
  faSortDown,
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faIdBadge,
  faTrash,
  faSpinner,
  faCog,
  faCogs,
  faPenAlt,
  faCheckDouble,
  faUsersClass,
  faUserFriends,
  faFileAlt,
  faCalculator,
  faCrown,
  faSignature,
  faUsers,
  faCircle,
  faFileContract,
  faPlus,
  faDownload,
  faFile,
  faFileArchive,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
  faFileImage,
  faPlusCircle,
  faFileDownload,
  faSearch,
  faPhone,
  faPhoneSlash,
  faTimes,
  faChartPie,
  faVoteYea,
  faVoteNay,
  faShieldCheck,
  faSortNumericDownAlt,
  faText,
  faChartArea,
  faQuestionCircle,
  faCheckSquare,
  faSquare,
  faSignOut,
  faMicrophone,
  faMicrophoneSlash,
  faBoxBallot,
  faClipboardListCheck,
  faVideo,
  faVideoSlash,
  faDesktop,
  faUsersCog,
  faHandPaper,
  faComment,
  faPaperclip,
  faPaperPlane,
  faExclamationTriangle,
  faPersonBooth,
  faInfo,
  faEnvelopeOpenText,
  faBan,
  faHandPointer,
  faHourglass,
  faUserClock,
  faBars,
  faUserCrown,
  faSlidersVSquare,
  faFileSignature,
  faClone,
  faSyncAlt,
  faClock
} from '@fortawesome/pro-light-svg-icons'

import {
  faCircle as solidFaCircle,
  faPlay as solidFaPlay,
  faBoxBallot as solidFaBoxBallot,
  faVoteYea as solidFaVoteYea,
  faEye as solidFaEye,
  faCheck as solidFaCheck,
  faCommentCheck as solidFaCommentCheck,
  faTimes as solidFaTimes,
  faHourglass as solidFaHourglass,
  faPen as solidFaPen,
  faCrown as solidFaCrown,
  faUser as solidFaUser,
  faBuilding as solidFaBuilding,
  faMailbox as solidFaMailbox,
  faExclamation as solidFaExclamation,
  faVolumeSlash as solidVolumeSlash,
  faGlobe as solidFaGlobe,
  faQuestion as solidFaQuestion
} from '@fortawesome/pro-solid-svg-icons'

import {
  faLinux as brandFaLinux,
  faWindows as brandFaWindows,
  faApple as brandFaApple,
  faAndroid as brandFaAndroid
} from '@fortawesome/free-brands-svg-icons'

Vue.component('BaseFaIcon', FontAwesomeIcon)

library.add(
  faCheck,
  faEllipsisH,
  faLifeRing,
  faSpinnerThird,
  faChevronRight,
  faCamera,
  faUser,
  faEnvelope,
  faEye,
  faEyeSlash,
  faBell,
  faCaretUp,
  faCaretDown,
  faMailbox,
  faPen,
  faSortUp,
  faSortDown,
  faArrowLeft,
  faArrowRight,
  faSyncAlt,
  faBuilding,
  faIdBadge,
  faTrash,
  faSpinner,
  faCog,
  faCogs,
  faPenAlt,
  faCheckDouble,
  faSquare,
  faUsersClass,
  faUserFriends,
  faFileAlt,
  faCalculator,
  faCrown,
  faSignature,
  faUsers,
  faUsersCog,
  faCircle,
  faFileContract,
  faPlus,
  faDownload,
  faFile,
  faFileArchive,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
  faFileImage,
  faPlusCircle,
  faFileDownload,
  faSearch,
  faPhone,
  faPhoneSlash,
  faTimes,
  faChartPie,
  faVoteYea,
  faVoteNay,
  faShieldCheck,
  faSortNumericDownAlt,
  faText,
  faChartArea,
  faQuestionCircle,
  faCheckSquare,
  faSignOut,
  faMicrophone,
  faMicrophoneSlash,
  faBoxBallot,
  faClipboardListCheck,
  faVideo,
  faVideoSlash,
  faDesktop,
  faHandPaper,
  faExclamationTriangle,
  faPersonBooth,
  faComment,
  faPaperclip,
  faPaperPlane,
  faInfo,
  faEnvelopeOpenText,
  faBan,
  faHandPointer,
  faHourglass,
  faUserClock,
  faBars,
  faUserCrown,
  faSlidersVSquare,
  faFileSignature,
  faClone,
  faClock,

  // SOLID
  solidFaCircle,
  solidFaPlay,
  solidFaBoxBallot,
  solidFaVoteYea,
  solidFaEye,
  solidFaCheck,
  solidFaTimes,
  solidFaHourglass,
  solidFaPen,
  solidFaCrown,
  solidFaUser,
  solidFaBuilding,
  solidFaMailbox,
  solidFaExclamation,
  solidVolumeSlash,
  solidFaGlobe,
  solidFaQuestion,
  solidFaCommentCheck,

  // BRANDS
  brandFaLinux,
  brandFaWindows,
  brandFaApple,
  brandFaAndroid
)
