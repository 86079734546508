import type { JsonPrivateKey, JsonPublicKey } from '~/packages/crypto/nurne/src/json'
import type { IKeyring } from '~/lib/zerauth/keyring'
import type { IAuthenticatedZerauthGraph } from '~/lib/zerauth/graph'
import { SecondaryKeyUsage } from '~/graphql/types/accounts-public'
import type {
  OpenKeyPair,
  SecondaryKey,
  SessionKeys
} from '~/lib/zerauth/key-types'
import { decryptSecondaryKey } from '~/lib/zerauth/session-keys'
import { exportV1PrivateKey, exportV1PublicKey } from '~/packages/crypto/nurne/src/primitives'
import type { KeyName } from '~/lib/zerauth/signature'

export type NurneKey = SecondaryKey<`nurne_${string}`, SecondaryKeyUsage.Nurne>

export interface IZerauthNurne {
  saveNewEncryptorKeyPair(ballotBoxId: string): Promise<JsonPublicKey>

  /** This crashes if they key does not exist **/
  fetchExistingNurneKey(ballotBoxId: string): Promise<JsonPrivateKey>
}

export function newZerauthNurne (
  secondaryKeyring: IKeyring,
  authenticatedGraph: IAuthenticatedZerauthGraph,
  sessionKeys: SessionKeys,
  ensureSecondaryKeyExists: <N extends KeyName, U extends SecondaryKeyUsage> (keyName: N, usage: U) => Promise<OpenKeyPair<SecondaryKey<N, U>>>
): IZerauthNurne {
  return {
    async fetchExistingNurneKey (ballotBoxId: string): Promise<JsonPrivateKey> {
      const keyName = `nurne_${ballotBoxId}` as const
      let key = secondaryKeyring.extractNurneKey(keyName)

      if (!key) {
        const fetchedKey = await authenticatedGraph.fetchSecondaryKey(keyName, SecondaryKeyUsage.Nurne)
        if (!fetchedKey) { throw new Error(`Nürne Key for ${keyName} was not found`) }

        key = await decryptSecondaryKey(fetchedKey, sessionKeys.masterKey)
      }

      const privateKey = key.keyPair.privateKey
      return { PrivateKey: await exportV1PrivateKey(privateKey), AlgorithmVersion: 1 }
    },

    async saveNewEncryptorKeyPair (ballotBoxId: string): Promise<JsonPublicKey> {
      const keyPair = await ensureSecondaryKeyExists(`nurne_${ballotBoxId}`, SecondaryKeyUsage.Nurne)

      return { PublicKey: await exportV1PublicKey(keyPair.keyPair.publicKey), AlgorithmVersion: 1 }
    }

  }
}
