// This module contains all zerauth-related graph operations, wrapped to return the Zerauth key types.

import { GraphQLClient } from 'graphql-request'
import { decode as b64ToBuffer, encode as bufferToB64 } from 'base64-arraybuffer'

import {
  type ChallengeDataType,
  getSdk as accountsPublicSdk,
  type MasterKeyInputType,
  type SecondaryKeyInputType,
  type SessionTokenType,
  type TgtInputType,
  type UserInputType
} from '~/graphql/types/accounts-public'

import { type FetchMeQuery, getSdk as accountsPrivateSdk, type SecondaryKeyType, SecondaryKeyUsage } from '~/graphql/types/accounts'

import {
  CURRENT_ALGORITHM_VERSION,
  type EncryptedKey,
  type EncryptedKeyPair,
  type MasterKey, type NewSecondaryKey,
  type SecondaryKey,
  type SessionKey,
  type UnvalidatedSessionKey
} from '~/lib/zerauth/key-types'
import type { KeyName } from '~/lib/zerauth/signature'

const ACCOUNTS_PRIVATE_URI = process.env.ACCOUNTS_URI! + '/private'
const ACCOUNTS_PUBLIC_URI = process.env.ACCOUNTS_URI! + '/public'

const accountsPublicClient = accountsPublicSdk(new GraphQLClient(ACCOUNTS_PUBLIC_URI))

export type UserInfo = Omit<FetchMeQuery['me'], 'secondaryKeys'>
export type UserInfoOrganization = Readonly<UserInfo['organizations'][0]>

export interface IUserData {
  secondaryKeys: Array<EncryptedKeyPair<SecondaryKey>>
  userInfo: UserInfo
}

export interface IAuthenticatedZerauthGraph {
  fetchMasterKey (): Promise<EncryptedKey<MasterKey>>
  updateMasterKey (newMasterKey: EncryptedKey<MasterKey>): Promise<void>
  addSecondaryKey (newSecondaryKey: EncryptedKeyPair<NewSecondaryKey>): Promise<void>
  deleteSession (sessionId: string): Promise<void>
  fetchUserData (): Promise<IUserData>
  fetchSecondaryKey<N extends KeyName, U extends SecondaryKeyUsage> (name: N, usage: U): Promise<EncryptedKeyPair<SecondaryKey<N, U>> | null>
}

export function newAuthenticatedZerauthGraph (authenticatedFetch: typeof window.fetch): IAuthenticatedZerauthGraph {
  const accountsPrivateClient = accountsPrivateSdk(new GraphQLClient(ACCOUNTS_PRIVATE_URI, {
    credentials: 'include',
    fetch: authenticatedFetch
  }))

  async function fetchMasterKey (): Promise<EncryptedKey<MasterKey>> {
    const { me: { masterKeyUpdateData: data } } = await accountsPrivateClient.fetchMasterKey()

    return {
      encryptedContents: b64ToBuffer(data.encryptedKey),
      iv: b64ToBuffer(data.iv),
      saltB64: data.salt,
      version: CURRENT_ALGORITHM_VERSION
    }
  }

  async function updateMasterKey (newMasterKey: EncryptedKey<MasterKey>): Promise<void> {
    await accountsPrivateClient.updateMasterKey({ newMasterKey: masterKeyToInput(newMasterKey) })
  }

  async function addSecondaryKey (newSecondaryKey: EncryptedKeyPair<NewSecondaryKey>): Promise<void> {
    await accountsPrivateClient.addSecondaryKey({
      newSecondaryKey: secondaryKeyToInput(newSecondaryKey)
    })
  }

  async function deleteSession (sessionId: string): Promise<void> {
    await accountsPrivateClient.deleteSession({ id: sessionId })
  }

  async function fetchUserData (): Promise<IUserData> {
    const { secondaryKeys, ...userInfo } = (await accountsPrivateClient.fetchMe()).me

    return {
      secondaryKeys: secondaryKeys.map(graphToSecondaryKey),
      userInfo
    }
  }

  async function fetchSecondaryKey<N extends KeyName, U extends SecondaryKeyUsage> (name: N, _usage: U): Promise<EncryptedKeyPair<SecondaryKey<N, U>> | null> {
    const secondaryKey = (await accountsPrivateClient.fetchSecondaryKey({ name })).me.secondaryKey
    if (!secondaryKey) { return null }
    return graphToSecondaryKey(secondaryKey)
  }

  return {
    fetchMasterKey,
    updateMasterKey,
    addSecondaryKey,
    deleteSession,
    fetchUserData,
    fetchSecondaryKey
  }
}

export interface PairingChallenge {
  masterKey: EncryptedKey<MasterKey>
  sessionKey: EncryptedKey<SessionKey>
}

export async function newPairing (email: string, confirmationToken: string): Promise<PairingChallenge> {
  const challengeData = (await accountsPublicClient.newPairing({ email, confirmationToken })).newPairing

  return graphToPairingChallenge(challengeData)
}

export async function newPairingFromSecret (email: string, authenticatingSecret: string): Promise<PairingChallenge> {
  const challengeData = (await accountsPublicClient.newPairingFromSecret({ email, authenticatingSecret })).newPairingFromSecret

  return graphToPairingChallenge(challengeData)
}

// export async function fastLaneNewPairing (email: string, token: string): Promise<PairingChallenge> {
//   const challengeData = (await accountsPublicClient.fastLaneNewPairing({ email, token })).fastLaneNewPairing

//   return graphToPairingChallenge(challengeData)
// }

export async function resetAccount (email: string, resetCode: string, newMasterKey: EncryptedKey<MasterKey>, newSessionKey: EncryptedKeyPair<UnvalidatedSessionKey>): Promise<PairingChallenge> {
  const challengeData = (await accountsPublicClient.resetAccount({
    email,
    resetCode,
    masterKey: masterKeyToInput(newMasterKey),
    accountsKey: secondaryKeyToInput(newSessionKey)
  })).resetAccount

  return graphToPairingChallenge(challengeData)
}

export async function register (user: UserInputType, newMasterKey: EncryptedKey<MasterKey>, newSessionKey: EncryptedKeyPair<UnvalidatedSessionKey>, sendMfa: boolean): Promise<void> {
  const newUser = (await accountsPublicClient.register({
    user,
    masterKey: masterKeyToInput(newMasterKey),
    accountsKey: secondaryKeyToInput(newSessionKey),
    sendMfa
  })).register

  if (!newUser.id) {
    throw new Error('Inconsistent register response: id is empty')
  }

  if (newUser.firstName !== user.firstName || newUser.lastName !== user.lastName) {
    throw new Error('Inconsistent register response: firstName/lastName differs:' +
      `expected '${user.firstName}' '${user.lastName}', received '${newUser.firstName}' '${newUser.lastName}'`)
  }
}

// export async function fastLaneRegister (user: FastLaneUserInputType, newMasterKey: EncryptedKey<MasterKey>, newSessionKey: EncryptedKeyPair<UnvalidatedSessionKey>): Promise<PairingChallenge> {
//   const challengeData = (await accountsPublicClient.fastLaneRegister({
//     user,
//     masterKey: masterKeyToInput(newMasterKey),
//     accountsKey: secondaryKeyToInput(newSessionKey)
//   })).fastLaneRegister

//   return graphToPairingChallenge(challengeData)
// }

export async function refreshSession (tgt: TgtInputType): Promise<SessionTokenType> {
  return (await accountsPublicClient.refreshSession({ tgt })).refreshSession
}

// Internal stuff

function masterKeyToInput (key: EncryptedKey<MasterKey>): MasterKeyInputType {
  return {
    algorithmVersion: key.version,
    salt: key.saltB64,
    encryptedKey: bufferToB64(key.encryptedContents),
    iv: bufferToB64(key.iv)
  }
}

function secondaryKeyToInput (key: EncryptedKeyPair<NewSecondaryKey>): SecondaryKeyInputType {
  return {
    algorithmVersion: key.version,
    encryptedKey: bufferToB64(key.encryptedContents),
    id: key.id,
    iv: bufferToB64(key.iv),
    name: key.name,
    publicKey: bufferToB64(key.publicKey),
    usage: key.usage
  }
}

function graphToPairingChallenge (challengeData: ChallengeDataType): PairingChallenge {
  return {
    masterKey: {
      encryptedContents: b64ToBuffer(challengeData.encryptedMasterKey),
      iv: b64ToBuffer(challengeData.masterKeyIv),
      version: challengeData.algorithmVersion,
      saltB64: challengeData.salt
    },
    sessionKey: {
      name: 'accounts',
      encryptedContents: b64ToBuffer(challengeData.encryptedAccountsKey),
      iv: b64ToBuffer(challengeData.accountsKeyIv),
      usage: SecondaryKeyUsage.Signature,
      sessionKey: challengeData.sessionKey
    }
  }
}

function graphToSecondaryKey<N extends KeyName, U extends SecondaryKeyUsage> (secondaryKey: SecondaryKeyType): EncryptedKeyPair<SecondaryKey<N, U>> {
  return {
    publicKey: b64ToBuffer(secondaryKey.publicKey),
    iv: b64ToBuffer(secondaryKey.iv),
    encryptedContents: b64ToBuffer(secondaryKey.encryptedKey),
    name: secondaryKey.name as N,
    usage: secondaryKey.usage as U
  }
}
